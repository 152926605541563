import * as React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';

const RawFood = ({ data }) => {
  var pageTitle = 'Raw Food';

  return (
    <Layout
      slug="raw food"
      title={pageTitle}
      description="Learn more about the benefits of raw feeding and why we chose The Complete K9."
    >
      <h1>{pageTitle}</h1>
      <p className="text-xl text-center my-8 rounded">
        We are so excited to announce that we are once again a distributor for
        The Complete K9 Raw Food!
      </p>
      <div className="flex justify-center">
        <GatsbyImage
          image={getImage(data.complete_logo)}
          alt="complete K9 logo"
          className="mb-4 "
        />
      </div>
      <h2 className="text-center my-8">
        Why do we choose to feed our personal dogs raw?
      </h2>
      <p>
        While dogs have changed drastically over the many generations since
        humans have domesticated them, their digestive system remains very
        similar. Processed foods, filler ingredients and artificial additives
        are not items their digestive tract is designed to process. Feeding a
        raw diet eliminates all of the extras that our canine friends are unable
        to digest while still giving them all of the nutrients required. Raw
        feeding is about going back to the roots of a dog's digestive tract and
        feeding the diet our dogs are meant to have and thrive upon. Since
        making the switch to raw food with our own dogs we have observed
        positive changes in their health.
      </p>
      <p>
        For the first year of Elsie’s life she struggled with recurring ear
        infections. Even with multiple trips to the vet and antibiotics we were
        unable to get rid of them. Since making this change in her diet she has
        not had another ear infection. Over 2yrs later she is still ear
        infection free and has only visited the vet for her routine check
        up/vaccines.
      </p>
      <h2 id="boarding-team" className="text-center mt-20">
        Benefits of Raw Feeding
      </h2>
      <section className="flex flex-col lg:flex-row justify-between items-center mb-20">
        <div>
          <GatsbyImage
            image={getImage(data.rmb)}
            alt="rmb"
            className="!square-300 lg:!square-400 mb-4 rounded-full"
          />
        </div>
        <div className="lg:w-1/2">
          <h3>Improved dental health</h3>
          <p>
            Currently, over 80% of dogs aged 3+ are suffering from dental
            disease which makes it one of the most common diseases seen in dogs.
            This is a serious disease that leads to harmful, toxic bacteria
            entering the bloodstream and spreading to the rest of the body.
          </p>
          <p>
            Unlike humans, dogs do not have naturally occurring amylase in their
            saliva.<sup>1</sup> This enzyme is vital in being able to break down
            the starches in carbs. When not broken down, these starches provide
            a rich environment for bacteria to feed upon.<sup>2</sup> Raw dog
            food contains naturally occuring enzymes which help to break down
            the food properly preventing the accumulation of tartar and the
            formation of plaque. These additional enzymes, along with the
            reduced amount of simple carbs in raw food, helps create a healthier
            environment in your dog's mouth by preventing the growth of oral
            bacteria. These factors greatly reduce the risk of dental disease
            occurring which leads to an overall increase in health
          </p>
        </div>
      </section>
      <section className="flex flex-col lg:flex-row-reverse justify-between items-center mb-20">
        <div>
          <GatsbyImage
            image={getImage(data.digestion)}
            alt="Kibble vs Raw"
            className="!square-300 lg:!square-400 mb-4 rounded-full"
          />
        </div>
        <div className="lg:w-1/2">
          <h3>Increased Digestive Heath</h3>
          <p>
            Since the vitamins and minerals in a raw diet are all coming from
            natural sources the food is easier to digest and absorb than the
            synthetic versions. This increased absorption rate means they are
            able to efficiently receive their daily intake of nutrients.
          </p>
          <p>
            Want to see this in action? Dogs who are switched to raw diets have,
            on average, a 60% reduction in stool size.<sup>3</sup> This is
            because in feeding a species appropriate diet you are giving your
            dog a food they can utilize. Instead of passing all the ingredients
            their digestive system can't handle they are able to absorb almost
            everything and are only passing a small portion of what they intake
            <sup>4</sup>
          </p>
        </div>
      </section>
      <section className="flex flex-col lg:flex-row justify-between items-center mb-20">
        <div>
          <GatsbyImage
            image={getImage(data.shine)}
            alt="Raw Fed Shine"
            className="!square-300 lg:!square-400 mb-4 rounded-full"
          />
        </div>
        <div className="lg:w-1/2">
          <h3>Healthier skin and coat</h3>
          <p>
            Raw diets contain many healthy fats that help support skin and coat
            health. One example is Omega Fatty acids which are essential to
            having a healthy coat and skin as well as providing
            anti-inflammatory benefits. The naturally occurring Omega-3 in
            ingredients such as salmon are easier for our dogs to absorb and
            utilize than the synthetic versions often added into kibble.
            <sup>4</sup>
          </p>
          <p>
            Healthier skin and coat can also help with reducing smell by
            reducing skin conditions that can be related to diet. Conditions
            such as hot spots, yeast overgrowth and flakiness can all be reduced
            by having a diet that has bioavailable nutrients.<sup>5</sup>
          </p>
        </div>
      </section>
      <section className="flex flex-col lg:flex-row-reverse justify-between items-center mb-20">
        <div>
          <GatsbyImage
            image={getImage(data.weight)}
            alt="Bone and Joint Suppport"
            className="!square-300 lg:!square-400 mb-4 rounded-full"
          />
        </div>
        <div className="lg:w-1/2">
          <h3>Joint and Bone Support</h3>
          <p>
            The crushed bone in a raw fed diet contains many important
            components such as calcium, glucosamine, phosphorus, collagen,
            chondroitin and marrow. These ingredients are crucial for ensuring
            that our dogs bones and joints are healthy.
          </p>
          <p>
            Studies done by Association of Pet Obesity Prevention<sup>6</sup>{' '}
            show that over 50% of dogs are overweight or obese. Raw diets allow
            for increased weight control due to the reduced amount of carbs.
            Appropriate weight management can not only improve joint health, it
            also decreases the risk of cancer, diabetes, heart disease, bladder
            stones and more. Weight management is essential in ensuring the
            health and longevity of our pets.
          </p>
        </div>
      </section>
      <section className="flex flex-col lg:flex-row justify-between items-center mb-20">
        <div>
          <GatsbyImage
            image={getImage(data.bowl)}
            alt="Raw Food Bowl"
            className="!square-300 lg:!square-400 mb-4 rounded-full"
          />
        </div>
        <div className="lg:w-1/2">
          <h3>Immunity Support</h3>
          <p>
            The high levels of quality protein contained within a raw fed diet
            has amino acids which help to support your dog's immune system. Raw
            food is also rich in fatty acids, immune boosting nutrients and
            naturally occurring microbes. The cooking process used when
            manufacturing kibble kills some of the nutrients available and the
            synthetic nutrients are not as easily absorbed as the natural
            bioavailable ones found in raw diets<sup>4</sup>
          </p>
          <p>
            Gut health is directly related to immunity. The live enzymes and
            probiotics provided in a raw diet help provide a healthy gut
            environment which in turn helps immunity.<sup>7</sup>
          </p>
        </div>
      </section>
      <h2 className="text-center my-8">Why Complete K9?</h2>
      <p>
        Complete K9 is a local company that produces locally sourced and
        manufactured raw food diets and recreational chews . All products made
        are human grade and produced without fillers, additives and
        preservatives. To ensure the safety of you and your pets. Complete K9
        removes the bacteria from the meat during the manufacturing process{' '}
      </p>
      <p>
        In addition to their food safety practices, the Complete K9 also has a
        pet food specialist on staff to ensure they are providing quality,
        nutritious meals. Multiple different proteins, including single protein
        options, help to ensure there is an option for every pet, including
        those with allergies.
      </p>
      <p>
        We truly believe that the Complete K9 provides an excellent product at a
        great, affordable price.{' '}
      </p>
      <h2 id="grooming-team" className="text-center">
        Delivery Info
      </h2>
      <table className="mb-4">
        <thead>
          <tr>
            <th rowspan="2">
              Order Date <br /> (midnight)
            </th>
            <th colspan="2">Delivery Date</th>
          </tr>
          <tr>
            <th>Stettler</th>
            <th>Castor</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Sunday April 30th</td>
            <td>Monday May 1st</td>
            <td>Monday May 1st</td>
          </tr>
          <tr>
            <td>Sunday May 7th</td>
            <td>Monday May 8th</td>
            <td>Monday May 8th</td>
          </tr>
          <tr>
            <td>Sunday May 14th</td>
            <td>Monday May 15th</td>
            <td>Monday April 15th</td>
          </tr>
          <tr>
            <td>Sunday May 21st</td>
            <td>Monday May 22nd</td>
            <td>Monday May 22nd</td>
          </tr>
          <tr>
            <td>Sunday May 28th</td>
            <td>Monday May 29th</td>
            <td>Monday May 29th</td>
          </tr>
          <tr>
            <td>Sunday June 4th</td>
            <td>Monday June 5th</td>
            <td>Monday June 5th</td>
          </tr>
        </tbody>
      </table>
      <section>
        <h2>References</h2>
        <p>
          1. S. Pasha et al., The Saliva Proteome of Dogs: Variations Within and
          Between Breeds and Between Species. Wiley Analytical Science Journals
          (2018) (available at
          https://analyticalsciencejournals.onlinelibrary.wiley.com/doi/full/10.1002/pmic.201700293).
        </p>
        <p>
          2.Hale FA. Dental caries in the dog. Can Vet J. 2009
          Dec;50(12):1301-4. PMID: 20190984; PMCID: PMC2777300.
        </p>
        <p>
          3. H. Neshovska, Z. Shindarska, Comparative study of the digestibility
          of dry and raw food in dogs (2021).
        </p>
        <p>
          4. Swanson KS, Kuzmuk KN, Schook LB, Fahey GC Jr. Diet affects
          nutrient digestibility, hematology, and serum chemistry of senior and
          weanling dogs. J Anim Sci. 2004 Jun;82(6):1713-24. doi:
          10.2527/2004.8261713x. PMID: 15216999.
        </p>
        <p>
          5. A. Hielm-Björkman et al., Puppyhood diet as a factor in the
          development of owner-reported allergy/atopy skin signs in adult dogs
          in Finland . Wiley Online Library (2021) (available at
          https://onlinelibrary.wiley.com/doi/10.1111/jvim.16211).{' '}
        </p>
        <p>6. https://petobesityprevention.org/about-the-data </p>
        <p>
          7. Vuori, K.A., Hemida, M., Moore, R. et al. The effect of puppyhood
          and adolescent diet on the incidence of chronic enteropathy in dogs
          later in life. Sci Rep 13, 1830 (2023).
          https://doi.org/10.1038/s41598-023-27866-z
        </p>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    family: file(relativePath: { eq: "family.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    rmb: file(relativePath: { eq: "rmb.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 700
          height: 700
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
    shine: file(relativePath: { eq: "shine.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 700
          height: 700
          placeholder: BLURRED
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
    digestion: file(relativePath: { eq: "digestion.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 700
          height: 700
          placeholder: BLURRED
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
    weight: file(relativePath: { eq: "fit.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 700
          height: 700
          placeholder: BLURRED
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
    complete_logo: file(relativePath: { eq: "complete_logo.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 500, placeholder: BLURRED)
      }
    }
    bowl: file(relativePath: { eq: "raw_food.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          height: 600
          placeholder: BLURRED
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
    digestion: file(relativePath: { eq: "digestion.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 700
          height: 700
          placeholder: BLURRED
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
    vanessagroom: file(relativePath: { eq: "vanandvi.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 700
          height: 700
          placeholder: BLURRED
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
    cam: file(relativePath: { eq: "cam_cow.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 700
          height: 700
          placeholder: BLURRED
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
  }
`;

export default RawFood;
